import { ComponentDataTable, RestApiClient } from '@develos/light-client-vue';
import type { IPlatformStatusDetails } from '../types/IPlatformStatusDetails';

const PlatformStatusesApiClient = {
  getPlatformStatusesTableConfiguration(): Promise<ComponentDataTable<IPlatformStatusDetails>> {
    return RestApiClient.get<ComponentDataTable<IPlatformStatusDetails>>('PlatformStatuses/GetLiveDataTableConfiguration');
  }
};

export default PlatformStatusesApiClient;
