import Vue from 'vue';
import { FormGroup, Label, Control, ISelectInputOption } from '@develos/light-client-vue';

const SelectFilterForm = Vue.component('NumericFromToForm', {
    props: {
        label: String,
        value: Number as () => number | null,
        options: Array as () => ISelectInputOption[],
        disabledSorting: Boolean
    },
    methods: {
        updateToElement(value: any) {
            this.$emit('input', value);
        }
    },
    render(h: any) {
        const value = (this.value !== null && this.value !== undefined) ? this.value.toString() : undefined;
        return (
            <FormGroup>
                <Label size="sm">
                    {this.label}
                </Label>
                <Control.Select
                    value={value}
                    optionsNotSorted={this.disabledSorting}
                    selectOptions={this.options}
                    onChange={this.updateToElement}
                />
            </FormGroup>
        );
    }
});

export default SelectFilterForm;
