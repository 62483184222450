import Vue from 'vue';
import styles from './ListGroup.module.scss';

const ListGroup = Vue.component('ListGroup', {
  render(h) {
    return (
      <div class={styles.listGroup}>
        {this.$slots.default}
      </div>
    );
  }
});

export default ListGroup;
