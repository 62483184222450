import TrafficMixin from '@/logic/helpers/TrafficMixin';
import {
    Badge,
    Button,
    Card,
    Modal,
    ComponentDataTable,
    emptyComponentDataTable,
    PageTitle,
    Table,
    TranslationService,
    CoreLoader,
    ButtonColor
} from '@develos/light-client-vue';
import { IMetricLocalization, IVehicleCategory, IVehicleRecordDetails } from '../../types';
import VehicleListFilter from './components/VehicleListFilter';
import VehicleListService from './services/VehicleListService';
import { emptyHistoryFilter, IHistoryFilter } from './types/IHistoryFilter';
import { IVehicleByIdRequest } from './types/IVehicleByIdRequest';
import { IVehicleListElement } from './types/IVehicleListElement';
import VehicleDetails from '../VehicleDetails/VehicleDetails';

const VehicleList = TrafficMixin.component('VehicleList', {
    data: () => {
      return {
        isDataLoading: false,
        traficListTable: { ...emptyComponentDataTable } as ComponentDataTable<IVehicleListElement>,
        filterFormState: { ...emptyHistoryFilter } as IHistoryFilter,
        availableCategories: [] as IVehicleCategory[],
        isModalDataLoading: false,
        showVehicleRecordModal: false,
        activeVehicleRecordDetails: null as IVehicleRecordDetails | null,
        componentName: 'VehicleList'
      };
    },
    mixins: [TrafficMixin],
    computed: {
      currentLocalization(): IMetricLocalization {
          /* eslint-disable */
          this.traficListTable = { ...emptyComponentDataTable } as ComponentDataTable<IVehicleListElement>;
        return this.$store.state.currentMetricLocalization;
      }
    },
    async created() {
      this.availableCategories = await VehicleListService.getVehicleCategories();
    },
    methods: {
      async getVehicleRecordsList(filterForm: IHistoryFilter) {
        this.isDataLoading = true;
        if (this.currentLocalization !== null) {
          filterForm.vehicleRecordsRequest!.metricLocalizationUid = this.currentLocalization.uId;
          try {
            this.traficListTable = await VehicleListService.GetVehicleRecords(filterForm);
          } finally {
            this.isDataLoading = false;
          }
        }
      },
      getCategoryCostDesc(categoryId: number): string {
        const category = this.availableCategories.find((obj: IVehicleCategory) => {
          return obj.id === categoryId;
        });
        if (category !== undefined) {
            return category.name;
        }
        return categoryId.toString();
      },
      async handleFilterSubmit(filterForm: IHistoryFilter) {
        this.getVehicleRecordsList(filterForm);
      },
      async handleRowAction(vehicleRecordDetails: IVehicleListElement) {
        this.showVehicleRecordModal = true;
        this.isModalDataLoading = true;
        this.activeVehicleRecordDetails = await this.getVehicleRecordById(vehicleRecordDetails);
        this.isModalDataLoading = false;
      },
      getVehicleRecordById(vehicleRecordDetails: IVehicleListElement) {
        const vehicleByIdRequest: IVehicleByIdRequest = {
          metricPointPlatformUId: vehicleRecordDetails.metricPointPlatformUId,
          id: vehicleRecordDetails.id
        };
        return VehicleListService.getVehicleRecordById(vehicleByIdRequest);
      }
    },
    render(h: any) {
      const slots: any = {
        category: (props: {row: IVehicleListElement; columns: string; index: number }) => {
          return this.getCategoryCostDesc(props.row.categoryCost);
        },
        isOverweight: (props: {row: IVehicleListElement; columns: string; index: number }) => {
          return (
            <Badge variant={this.badgeVariant(props.row.isOverweight)} size="sm">
              {this.isOwerweightText(props.row.isOverweight)}
            </Badge>
          );
        },
        lane: (props: {row: IVehicleListElement; columns: string; index: number }) => {
          return (
            <Badge variant={this.badgeVariant(props.row.lane === 0)} size="sm">
              {this.isSensorMissed(props.row.lane === 0)}
            </Badge>
          );
        },
        metricPointPlatformUId: (props: {row: IVehicleListElement; columns: string; index: number }) => this.mapToPlatform(props.row.metricPointPlatformUId),
        actions: (props: {row: IVehicleListElement; columns: string; index: number }) => {
          return (
              <Button
                size='sm'
                color={ButtonColor.Primary}
                onClick={() => this.handleRowAction(props.row)}>
                <i class='fa fa-pencil-square-o'/> Szczegóły
              </Button>
          );
        }
      };
      return (
        <div>
          <PageTitle title={TranslationService.translateComponent('Ruch drogowy | Historia ruchu pojazdów', this.componentName)} />
          <VehicleListFilter availableCategories={this.availableCategories} onFilterSubmit={this.handleFilterSubmit} />
          <Card title={TranslationService.translateComponent('Wyniki wyszukiwania', this.componentName)}>
            <CoreLoader isLoading={this.isDataLoading} isCentered={true} hasOpacity={true} isSmall={true} />
            <Table
              data={this.traficListTable.values}
              headers={this.traficListTable.headers}
              options={{
                  ...this.traficListTable.options,
                  skin: 'table-striped'
              }}
              slots={slots}
            />
          </Card>
          <Modal
            title={TranslationService.translateComponent('Szczegóły przejazdu', this.componentName)}
            isOpen={this.showVehicleRecordModal}
            onClose={() => { this.activeVehicleRecordDetails = null; this.showVehicleRecordModal = false; }}
          >
            <VehicleDetails activeVehicleRecordDetails={this.activeVehicleRecordDetails} isModalDataLoading={this.isModalDataLoading}/>
          </Modal>
        </div>
      );
    }
});

export default VehicleList;
