export enum VehicleImageType {
    FullVehicle,
    RegistrationPlate,
    FrontOfVehicle
}

export interface IBinaryRecordDetails {
    image: string;
    vehicleId: number;
    imageType: VehicleImageType;
}
