import VehicleCategoriesService from '@/logic/services/VehicleCategoriesService';
import { IVehicleCategory, IVehicleRecordDetails } from '@/logic/types';
import { ComponentDataTable } from '@develos/light-client-vue';
import VehicleListApiClient from '../api/VehicleListApiClient';
import { IHistoryFilter } from '../types/IHistoryFilter';
import { IVehicleByIdRequest } from '../types/IVehicleByIdRequest';
import { IVehicleListElement } from '../types/IVehicleListElement';

class VehicleListService {
  public async GetVehicleRecords(request: IHistoryFilter): Promise<ComponentDataTable<IVehicleListElement>> {
    const vehicleRecords = await VehicleListApiClient.getVehicleRecords(request);
    return vehicleRecords;
  }

  public async getVehicleCategories(): Promise<IVehicleCategory[]> {
    const vehicleCategories = await VehicleCategoriesService.getVehicleCategories();
    return vehicleCategories;
  }

  public async getVehicleRecordById(request: IVehicleByIdRequest): Promise<IVehicleRecordDetails> {
    const vehicleRecord = await VehicleListApiClient.getVehicleRecordById(request);
    return vehicleRecord;
  }
}

export default new VehicleListService();
