import Vue from 'vue';
import styles from './ListGroup.module.scss';

const ListGroupItem = Vue.component('ListGroupItem', {
  render(h) {
    return (
      <div class={styles.listGroupItem}>
        {this.$slots.default}
      </div>
    );
  }
});

export default ListGroupItem;
