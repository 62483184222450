import store from '@/store/store';
import LocalizationHeaderDropdownAPIClient from '../api/LocalizationHeaderDropdownAPIClient';

const LocalizationHeaderDropdownService = {
  async loadUserMetricsLocalizations() {
    if (store.state.currentMetricLocalization === null) {
      const metricLocalizations = await LocalizationHeaderDropdownAPIClient.getMetricsLocalizations();
      store.state.metricLocalizations = metricLocalizations;
      if (metricLocalizations.length > 0) {
        store.state.currentMetricLocalization = metricLocalizations[0];
      }
    }
  }
};

export default LocalizationHeaderDropdownService;
