import TrafficMixin from '@/logic/helpers/TrafficMixin';
import './_axlesDistance.scss';
import { Progress } from '@develos/light-client-vue';

const AxlesDistance = TrafficMixin.component('AxlesDistance', {
  data: () => {
    return {
      variants: ['success', 'warning', 'danger']
    };
  },
  mixins: [TrafficMixin],
  props: {
    axelsDistances: Array as () => number[],
    axelsWeights: Array as () => number[]
  },
  methods: {
    sumWDists() {
      if (this.axelsDistances.length === 0) {
        return 0;
      }
      let sum = 0;
      this.axelsDistances.forEach((element) => {
          sum += element;
      });
      return sum / 100;
    },
    calcMarginLeftForAxle(index: number): string {
      if (this.axelsDistances.length === 0) {
        return '';
      }
      let sum = 0;
      for (let i = 0; i < index; i++) {
          sum += this.axelsDistances[i];
      }
      sum = ((sum) / (this.sumWDists() * 100)) * 100;
      const position = (index !== 0 && index !== this.axelsDistances.length) ? `calc(${sum.toFixed(2)}% - 20px)` : `${sum.toFixed(2)}%`;
      return `left: ${position}`;
    }
  },
  render(h: any) {
    return (
        <div class="row mt-1">
            <div class='full-width'>
                <Progress.BarGroup max={this.sumWDists()} class='mt-1' show-value={true}>
                  {this.axelsDistances.map((axel, index) => {
                    return <Progress.Bar
                              key={`axel${index}`}
                              max={this.sumWDists()}
                              value={axel / 100}
                              variant={this.variants[index % 3]}>
                              { (axel / 100).toFixed(2) } m
                            </Progress.Bar>;
                  })}
                </Progress.BarGroup>
            </div>
            <div class="col-12" style='min-height: 15px; margin-top:5px;'>
              {this.axelsWeights.map((elem, index) => {
                return <p class='rolled'
                          style={this.calcMarginLeftForAxle(index)}
                          key={`axellabel${index}`}>
                          {elem} kg
                        </p>;
              })}
            </div>
        </div>
    );
  }
});

export default AxlesDistance;
