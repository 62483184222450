import Vue from 'vue';
import { ICalibrationVehicleDetails, emptyCalibrationVehicleDetails, calibrationValidationSchema } from './types/ICalibrationVehicleDetails';
import { IAxleDetails, emptyAxleDetails } from '@/logic/components/CalibrationReports/types/IAxleDetails';
import { TranslationService, Card, Button, CoreLoader, FormGroup, Control, Label, ButtonVariant, ErrorMessage, FormGroupVariants, ButtonType, ButtonColor } from '@develos/light-client-vue';
import { IMetricLocalization } from '@/logic/types';
import CalibrationReportsAPIClient from './api/CalibrationReportsAPIClient';
import { MetricLocalizationsFilterForm } from '../../filters';

const CalibrationReports = Vue.component('CalibrationReports', {
  data: () => {
    return {
      isLoadingUserInitialData: false,
      calibrationVehicleDetails: { ...emptyCalibrationVehicleDetails } as ICalibrationVehicleDetails,
      componentName: 'CalibrationReports'
    };
  },
  validations: {
    calibrationVehicleDetails: { ...calibrationValidationSchema }
  },
  methods: {
    updateAxelsList() {
      const elementsToAddCount = Number(this.calibrationVehicleDetails.axleNumber.toString()) - Number(this.calibrationVehicleDetails.axleDetails.length.toString());
      if (elementsToAddCount > 0) {
        for (let i = 0; i < elementsToAddCount; i++) {
          const emptyElement = { ...emptyAxleDetails };
          this.calibrationVehicleDetails.axleDetails.push(emptyElement);
        }
      } else {
        for (let i = elementsToAddCount; i < 0; i++) {
          this.calibrationVehicleDetails.axleDetails.pop();
        }
      }
    },
    loadAxleList() {
      const element: IAxleDetails[] = [];
      for (let i = 0; i < Number(this.calibrationVehicleDetails.axleNumber.toString()); i++) {
          const emptyElement = { ...emptyAxleDetails };
          element.push(emptyElement);
        }

      this.calibrationVehicleDetails.axleDetails = element;
    },
    async generateReport() {
      this.$v.$touch();
      const isFormValid = !this.$v.calibrationVehicleDetails.$invalid;
      if (isFormValid) {
        this.isLoadingUserInitialData = true;
        this.calibrationVehicleDetails.vehicleRecordsRequest.metricLocalizationUid = this.currentLocalization.uId;
        await CalibrationReportsAPIClient.createCalibrationReport(this.calibrationVehicleDetails);
        this.isLoadingUserInitialData = false;
      }
    },
    async loadInitialData() {
      this.isLoadingUserInitialData = true;
      this.loadAxleList();
      this.isLoadingUserInitialData = false;
    },
    getLocalDate(value: Date) {
      const parsedDate = new Date(value);
      const utcDate = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 0, 0);
      return utcDate.getMilliseconds();
    },
    getUTCDate(value: Date) {
      const parsedDate = new Date(value);
      const utcDate = new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 0, 0));
      return utcDate;
    }
  },
  computed: {
    currentLocalization(): IMetricLocalization {
      return this.$store.state.currentMetricLocalization;
    }
  },
  async created() {
    await this.loadInitialData();
  },
  render: function (h: any) {
    return (
        <div>
          <Card title={TranslationService.translateComponent('Generowanie raportów kalibracji', this.componentName)}>
            {this.isLoadingUserInitialData && <CoreLoader isSmall={true} isCentered={true} hasOpacity={true}/>}
            <div class="row mb-2">
              <div class="col-lg-6">
                <FormGroup className="row mb-2" variant={FormGroupVariants.Inline}>
                  <Label className="col-md-4" size="sm">Numer rejestracyjny</Label>
                  <div class="col-md-8">
                    <Control.Text
                        value={this.calibrationVehicleDetails.plateNumber}
                        onBlur={() => this.$v.calibrationVehicleDetails.plateNumber && this.$v.calibrationVehicleDetails.plateNumber.$touch()}
                        onChange={(value: string) => this.calibrationVehicleDetails.plateNumber = value}/>
                    <ErrorMessage validatorItem={this.$v.calibrationVehicleDetails.plateNumber}/>
                  </div>
                </FormGroup>
                <FormGroup className="row mb-2" variant={FormGroupVariants.Inline}>
                  <Label className="col-md-4" size="sm">Waga[kN]</Label>
                  <div class="col-md-8">
                    <Control.Text
                        value={this.calibrationVehicleDetails.weight.toString()}
                        onBlur={() => this.$v.calibrationVehicleDetails.weight && this.$v.calibrationVehicleDetails.weight.$touch()}
                        onChange={(value: string) => this.calibrationVehicleDetails.weight = parseFloat(value)}/>
                    <ErrorMessage validatorItem={this.$v.calibrationVehicleDetails.weight}/>
                  </div>
                </FormGroup>
                <FormGroup className="row mb-2" variant={FormGroupVariants.Inline}>
                  <Label className="col-md-4" size="sm">Nacisk osi podwójnych/potrójnych[kN]</Label>
                  <div class="col-md-8">
                    <Control.Text
                        value={this.calibrationVehicleDetails.axleGroupsWeight.toString()}
                        onBlur={() => this.$v.calibrationVehicleDetails.axleGroupsWeight && this.$v.calibrationVehicleDetails.axleGroupsWeight.$touch()}
                        onChange={(value: string) => this.calibrationVehicleDetails.axleGroupsWeight = parseFloat(value)}/>
                    <ErrorMessage validatorItem={this.$v.calibrationVehicleDetails.axleGroupsWeight}/>
                  </div>
                </FormGroup>
                <FormGroup className="row mb-1" variant={FormGroupVariants.Inline}>
                  <Label className="col-md-4" size="sm">Liczba osi</Label>
                  <div class="col-md-8">
                    <Control.Text
                        value={this.calibrationVehicleDetails.axleNumber.toString()}
                        onBlur={() => this.$v.calibrationVehicleDetails.axleNumber && this.$v.calibrationVehicleDetails.axleNumber.$touch()}
                        onChange={(value: string) => {
                          this.calibrationVehicleDetails.axleNumber = parseInt(value);
                          this.updateAxelsList();
                        }}/>
                    <ErrorMessage validatorItem={this.$v.calibrationVehicleDetails.axleNumber}/>
                  </div>
                </FormGroup>
              </div>
              <div class="col-lg-6">
                <FormGroup className="mb-1" variant={FormGroupVariants.Inline}>
                  <Label size="sm" className='mr-1'>Data</Label>
                  <Control.Date
                      value={this.calibrationVehicleDetails.date.date ? `${new Date(parseInt(this.calibrationVehicleDetails.date.date)).getTime()}` : ''}
                      onChange={(value: string) => this.calibrationVehicleDetails.date.date = new Date(parseInt(value)).toISOString()}
                  />
                </FormGroup>
                <MetricLocalizationsFilterForm
                    value={this.calibrationVehicleDetails.vehicleRecordsRequest.metricPointsUIds}
                    onInput={(value: string[]) => this.calibrationVehicleDetails.vehicleRecordsRequest.metricPointsUIds = value}/>
              </div>
            </div>
          </Card>
          {this.calibrationVehicleDetails.axleDetails.map((elem, index) => {
            return <Card title={TranslationService.translateComponent(`Numer osi : ${index + 1}`, this.componentName)}>
              <div class="row mb-2">
                <div class="col-sm-6 col-lg-4">
                  <FormGroup className="mb-2" variant={FormGroupVariants.Inline}>
                    <Label size="sm" className="mr-1">Lewe koło[kN]</Label>
                    <Control.Text
                        value={elem.leftWheelWeight.toString()}
                        onChange={(value: string) => elem.leftWheelWeight = parseFloat(value)}/>
                  </FormGroup>
                </div>
                <div class="col-sm-6 col-lg-4">
                  <FormGroup className="mb-2" variant={FormGroupVariants.Inline}>
                    <Label size="sm" className="mr-1">Prawe koło[kN]</Label>
                    <Control.Text
                        value={elem.rightWheelWeight.toString()}
                        onChange={(value: string) => elem.rightWheelWeight = parseFloat(value)}/>
                  </FormGroup>
                </div>
                <div class="col-sm-6 col-lg-4">
                  <FormGroup className="mb-2" variant={FormGroupVariants.Inline}>
                    <Label size="sm" className="mr-1">Następna oś[m]</Label>
                    <Control.Text
                        value={elem.nextAxleDistance.toString()}
                        onChange={(value: string) => elem.nextAxleDistance = parseFloat(value)}/>
                  </FormGroup>
                </div>
              </div>
            </Card>;
          })}
          <Button size='md' onClick={this.generateReport} type={ButtonType.Submit} color={ButtonColor.Primary} variant={ButtonVariant.More}>
            <i class='fas fa-save mr-1'/> Generuj
          </Button>
        </div>
    );
  }
});

export default CalibrationReports;
