import Vue from 'vue';
import AppLoader from './AppLoader';
import router from './router';
import store from './store/store';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(AppLoader)
}).$mount('#app');
