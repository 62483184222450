import TrafficMixin from '@/logic/helpers/TrafficMixin';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { ComponentConsts } from './helpers/ComponentConsts';
import {
  ComponentDataTable,
  emptyComponentDataTable,
  PageTitle,
  Table,
  TranslationService,
  Badge,
  ConfigurationService
} from '@develos/light-client-vue';
import { IMetricLocalization } from '../../types';
import PlatformStatusesApiClient from './api/PlatformStatusesApiClient';
import type { IPlatformStatusDetails } from './types/IPlatformStatusDetails';
import { emptyStatusableButton } from '../LiveVehiclesRecords/types/IStatusableButton';

const PlatformStatuses = TrafficMixin.component('PlatformStatuses', {
  data: () => {
    return {
      isDataLoading: false,
      platformStatusesTable: { ...emptyComponentDataTable } as ComponentDataTable<IPlatformStatusDetails>,
      isModalDataLoading: false,
      isConnected: false,
      hubConnection: undefined as HubConnection | undefined,
      componentName: 'PlatformStatuses'
    };
  },
  computed: {
    currentLocalization(): IMetricLocalization {
      /* eslint-disable */
      this.platformStatusesTable = {...emptyComponentDataTable} as ComponentDataTable<IPlatformStatusDetails>;
      return this.$store.state.currentMetricLocalization;
    }
  },
  async beforeDestroy() {
    if (this.hubConnection && this.isConnected) {
      await this.closeConnection();
    }
  },
  async created() {
    this.platformStatusesTable = await PlatformStatusesApiClient.getPlatformStatusesTableConfiguration();

    await this.openConnection();
  },
  methods: {
    async openConnection(): Promise<void> {
      try {
        const centralApiUrl = await this.getCentralApiUrl();
        this.hubConnection = new HubConnectionBuilder().withUrl(`${centralApiUrl}platformStatusHub`).build();

        if (this.hubConnection !== undefined) {
          await this.hubConnection!.start();
          this.isConnected = true;

          this.hubConnection.on('ReceiveNewPlatformStatuses', this.onRecieveNewStatuses);
          this.hubConnection.on('ClosePlatformConnection', async () => {
            await this.closeConnection();
          });
          this.hubConnection.onclose(async () => {
            await this.closeConnection();
          });

          await this.hubConnection!.invoke('RegisterPlatformStatusClient', this.currentLocalization.uId);
        }
      } catch (error) {
        this.isConnected = false;
      }
    },
    async getCentralApiUrl(): Promise<string> {
      let centralApiUrl = await ConfigurationService.getAppSetting(ComponentConsts.AppSettingsCentralApiAddress);

      if (!centralApiUrl.endsWith('/')) {
        centralApiUrl = `${centralApiUrl}/`;
      }
      return centralApiUrl;
    },
    async closeConnection() {
      if (this.hubConnection && this.isConnected) {
        await this.hubConnection!.stop();
        this.isConnected = false;
      }
    },
    async onRecieveNewStatuses(records: IPlatformStatusDetails[]) {
      records.forEach((item) => {
        let existingElement = this.platformStatusesTable.values.find((x) => x.metricPointPlatformUId === item.metricPointPlatformUId);
        if (existingElement != undefined) {
          existingElement = item;
        } else {
          this.platformStatusesTable.values.unshift(item);
        }
      });
    },
    async getPlatformStatuses() {
      this.isDataLoading = true;
      if (this.currentLocalization !== null) {
        try {
          this.platformStatusesTable = await PlatformStatusesApiClient.getPlatformStatusesTableConfiguration();
        } finally {
          this.isDataLoading = false;
        }
      }
    },
    isPowerStatusOk(value: boolean): string {
      if (value) {
        return 'Aktywny';
      }
      return 'Nieaktywny';
    },
  },
  mixins: [TrafficMixin],
  watch: {
    async currentLocalization(value: IMetricLocalization, oldValue: IMetricLocalization) {
      this.platformStatusesTable.values = [];

      if (this.isConnected) {
        await this.closeConnection();
      }

      await this.openConnection();
    },
  },
  render(h: any) {
    const slotedScopes: any = {
      metricPointPlatformUId: (props: {
        row: IPlatformStatusDetails;
        columns: string;
        index: number
      }) => this.mapToPlatform(props.row.metricPointPlatformUId),
      status: (props: { row: IPlatformStatusDetails; columns: string; index: number }) => {
        return (
          <Badge variant={this.badgeVariant(props.row.status != 1)} size="sm">
            {this.isPowerStatusOk(props.row.status === 1)}
          </Badge>
        );
      },
    };
    return (
      <div>
        <PageTitle
          title={TranslationService.translateComponent('Dashboard | Stan punktów preselekcji', this.componentName)}/>
        {/* <div class='col-md-12 mb-2'>
            <Button
                className="ml-auto"
                color={this.connectionStatus.buttonColor}
                size="md"
                loading={this.connectionStatus.loading}
                onClick={this.toogleConnection}>{this.connectionStatus.buttonText}</Button>
          </div> */}
        <Table
          data={this.platformStatusesTable.values}
          headers={this.platformStatusesTable.headers}
          options={{
            ...this.platformStatusesTable.options,
            skin: 'table-striped'
          }}
          slots={slotedScopes}
        />
      </div>
    );
  }
});

export default PlatformStatuses;
