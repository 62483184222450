import Vue from 'vue';
import { IMetricPointPlatform } from '../types/IMetricPointPlatform';

const TrafficMixin = Vue.extend({
    methods: {
        isOwerweightText(record: boolean): string {
            if (record) {
                return 'Pojazd przeciążony';
            }
            return 'Waga poprawna';
        },
        isSensorMissed(value: boolean): string {
            if (value) {
                return 'Tak';
            }
            return 'Nie';
          },
        badgeVariant(value: boolean): string {
            if (value) {
                return '4';
            }
            return '3';
        },
        mapToFullPlatform(metricPointPlatformUId: string): string {
            const localizations = this.$store.state.currentMetricLocalization.metricPointPlatforms;
            const pointPlatrofm: IMetricPointPlatform | undefined = localizations.find((x: IMetricPointPlatform) => x.uId === metricPointPlatformUId);
            if (pointPlatrofm !== undefined) {
                return `${pointPlatrofm.name} (Droga nr: ${pointPlatrofm.roadNumber}, kierunek: ${pointPlatrofm.direction}, kilometr: ${pointPlatrofm.mileage})`;
            }
            return 'Brak danych';
        },
        mapToPlatform(metricPointPlatformUId: string): string {
            const localizations = this.$store.state.currentMetricLocalization.metricPointPlatforms;
            const pointPlatrofm: IMetricPointPlatform | undefined = localizations.find((x: IMetricPointPlatform) => x.uId === metricPointPlatformUId);
            if (pointPlatrofm !== undefined) {
                return pointPlatrofm.name;
            }
            return 'Brak danych';
        }
    }
});

export default TrafficMixin;
