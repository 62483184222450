import { IMetricLocalization } from '@/logic/types';
import { IVehicleCategory } from '@/logic/types/IVehicleCategory';
import { IRootState } from '@develos/light-client-vue';

export interface AppState extends IRootState {
    currentMetricLocalization: IMetricLocalization | null;
    metricLocalizations: IMetricLocalization[];
    vehicleCategories: IVehicleCategory[];
}

export const emptyAppState = {
    currentMetricLocalization: null as IMetricLocalization | null,
    metricLocalizations: [] as IMetricLocalization[],
    vehicleCategories: [] as IVehicleCategory[]
};

export const appStateMutations = {
    changeCurrentMetricLocalization(state: AppState, metricLocalization: IMetricLocalization) {
        state.currentMetricLocalization = metricLocalization;
    },
    clearStore(state: AppState) {
        state.metricLocalizations = [];
        state.currentMetricLocalization = null;
    }
};
