import Vue from 'vue';
import { IReportFilter } from './types/IReportFilter';
import ReportsService from './services/ReportsService';
import TrafficReportFilterForm from './components/TrafficReportFilterForm';
import { IMetricLocalization, IVehicleCategory } from '@/logic/types';
import { CoreLoader } from '@develos/light-client-vue';

const Reports = Vue.component('ReportsComponent', {
  data: () => {
    return {
      isLoadingReport: false,
      availableCategories: [] as IVehicleCategory[],
      componentName: 'Reports'
    };
  },
  async created() {
    this.availableCategories = await ReportsService.getVehicleCategories();
  },
  computed: {
    currentLocalization(): IMetricLocalization {
      return this.$store.state.currentMetricLocalization;
    }
  },
  methods: {
    async createReport(filterForm: IReportFilter) {
      this.isLoadingReport = true;
      filterForm.vehicleRecordsRequest.metricLocalizationUid = this.currentLocalization.uId;
      await ReportsService.createReport(filterForm);
      this.isLoadingReport = false;
    }
  },
  render(h: any) {
    return (
      <div class='row'>
        <div class='col-lg-12'>
          { this.isLoadingReport && <CoreLoader isCentered={true} hasOpacity={true} isSmall={true} /> }
          <TrafficReportFilterForm
            onFilterSubmit={this.createReport}
            availableCategories={this.availableCategories}/>
        </div>
      </div>
    );
  }
});

export default Reports;
