export type IAxleDetails = {
    number: number;
    leftWheelWeight: number;
    rightWheelWeight: number;
    nextAxleDistance: number;
}

export const emptyAxleDetails: IAxleDetails = {
    number: 1,
    leftWheelWeight: 0,
    rightWheelWeight: 0,
    nextAxleDistance: 0
};
