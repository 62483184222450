import { IFromToFilter, IVehicleCategory } from '@/logic/types';
import { TranslationService, Card, Button, ISelectInputOption, FormGroup, Control, Label, ButtonVariant, ButtonColor, ButtonType } from '@develos/light-client-vue';
import Vue from 'vue';
import { emptyReportFilter, IReportFilter } from '../types/IReportFilter';
import { MetricLocalizationsFilterForm, VehiclesWarningsForm, VehicleCategoriesForm, NumericFromToForm, DateTimesFromToForm, CheckBoxesFilterForm } from '../../../filters';
import { IVehicleWarningFilter } from '@/logic/types/IVehicleWarningFilter';
import SelectFilterForm from '@/logic/filters/SelectFilterForm';

const TrafficReportFilterForm = Vue.component('TrafficReportFilterForm', {
    data: () => {
      return {
        filterForm: { ...emptyReportFilter } as IReportFilter,
        reportFormats: [
            { key: '0', value: 'CSV' },
            { key: '1', value: 'PDF' },
            { key: '2', value: 'XML' }
        ] as ISelectInputOption[],
        reportTypes: [
            { key: '0', value: 'Zbiorczy dla kategorii' },
            { key: '1', value: 'Zbiorczy dla liczby pojazdów' },
            { key: '2', value: 'Zbiorczy dla grup wagowych' },
            { key: '3', value: 'Zbiorczy dla średniej prędkości' },
            { key: '4', value: 'Zbiorczy dla przekroczeń prędkości' },
            { key: '5', value: 'Zbiorczy dla maksymalnej prędkości' },
            { key: '6', value: 'Szczegółowy' }
        ] as ISelectInputOption[],
        aggregationTypes: [
            { key: '0', value: 'Godzinowo' },
            { key: '1', value: 'Dziennie' },
            { key: '2', value: 'Tygodniowo' },
            { key: '3', value: 'Miesięcznie' },
            { key: '4', value: 'Rocznie' }
        ] as ISelectInputOption[],
        months: [
            { key: '1', value: 'Styczeń' },
            { key: '2', value: 'Luty' },
            { key: '3', value: 'Marzec' },
            { key: '4', value: 'Kwiecień' },
            { key: '5', value: 'Maj' },
            { key: '6', value: 'Czerwiec' },
            { key: '7', value: 'Lipiec' },
            { key: '8', value: 'Sierpień' },
            { key: '9', value: 'Wrzesień' },
            { key: '10', value: 'Październik' },
            { key: '11', value: 'Listopad' },
            { key: '12', value: 'Grudzień' }
        ] as ISelectInputOption[],
        weekDays: [
            { key: '1', value: 'Poniedziałek' },
            { key: '2', value: 'Wtorek' },
            { key: '3', value: 'Środa' },
            { key: '4', value: 'Czwartek' },
            { key: '5', value: 'Piątek' },
            { key: '6', value: 'Sobota' },
            { key: '0', value: 'Niedziela' }
        ] as ISelectInputOption[],
        showFilterSection: true,
        componentName: 'TrafficReportFilterForm'
      };
    },
    props: {
        availableCategories: Array as () => IVehicleCategory[]
    },
    methods: {
        filterSubmit() {
            this.$emit('filterSubmit', this.filterForm);
        }
    },
    computed: {
        availableYears(): Array<ISelectInputOption<string>> {
            const result: Array<ISelectInputOption<string>> = [];
            const today = new Date();
            for (let i = 2018; i <= today.getFullYear(); i++) {
                result.push({ value: i.toString(), key: i.toString() });
            }
            return result;
        }
    },
    render(h: any) {
       return (
        <Card title={TranslationService.translateComponent('Filtrowanie wyników', this.componentName)}>
            <div class="row mb-2">
                <div class="col-lg-3">
                    <MetricLocalizationsFilterForm
                        value={this.filterForm.vehicleRecordsRequest.metricPointsUIds}
                        onInput={(value: string[]) => this.filterForm.vehicleRecordsRequest.metricPointsUIds = value } />
                </div>
                <div class="col-lg-3">
                    <VehiclesWarningsForm
                        value={this.filterForm.wehiclesWarnings}
                        onInput={(value: IVehicleWarningFilter) => this.filterForm.wehiclesWarnings = value } />
                </div>
                <div class="col-lg-3">
                    <VehicleCategoriesForm
                        options={this.availableCategories}
                        value={this.filterForm.categories}
                        onInput={(value: any) => this.filterForm.categories = value } />
                </div>
            </div>
            
            <div class="row mb-2">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3">
                            <NumericFromToForm
                                label='Liczba osi'
                                minValue="0"
                                value={this.filterForm.axelsCounts}
                                onInput={(value: IFromToFilter<number>) => this.filterForm.axelsCounts = value } />
                        </div>
                        <div class="col-lg-3">
                            <NumericFromToForm
                                label='Prędkość'
                                minValue="0"
                                value={this.filterForm.speed}
                                onInput={(value: IFromToFilter<number>) => this.filterForm.speed = value } />
                        </div>
                        <div class="col-lg-6">
                            <DateTimesFromToForm
                                label='Przedział czasowy'
                                value={this.filterForm.dates}
                                withTime={true}
                                onInput={(value: IFromToFilter<Date>) => this.filterForm.dates = value } />
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row mb-2">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-4">
                            <NumericFromToForm
                                label='Waga'
                                minValue="0"
                                value={this.filterForm.weight}
                                onInput={(value: IFromToFilter<number>) => this.filterForm.weight = value }/>
                        </div>
                        <div class="col-lg-4">
                            <SelectFilterForm
                                label='Typ raportu'
                                value={this.filterForm.selectedReportType}
                                options={this.reportTypes}
                                onInput={(value: string) => this.filterForm.selectedReportType = parseInt(value) }/>
                        </div>
                        <div class="col-lg-4">
                            <SelectFilterForm
                                label='Format raportu'
                                value={this.filterForm.selectedReportFormat}
                                options={this.reportFormats}
                                onInput={(value: string) => this.filterForm.selectedReportFormat = parseInt(value) }/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row mb-2">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-2">
                            <SelectFilterForm
                                label='Zagregowany'
                                value={this.filterForm.selectedAggregationType}
                                onInput={(value: string) => this.filterForm.selectedAggregationType = parseInt(value) }
                                options={this.aggregationTypes} />
                        </div>
                        <div class="col-lg-2">
                            <SelectFilterForm
                                label='Miesiąc'
                                disabledSorting={true}
                                value={this.filterForm.selectedMonth}
                                onInput={(value: string) => this.filterForm.selectedMonth = parseInt(value) }
                                options={this.months} />
                        </div>
                        <div class="col-lg-2">
                            <SelectFilterForm
                                label='Rok'
                                value={this.filterForm.selectedYear}
                                onInput={(value: string) => this.filterForm.selectedYear = parseInt(value) }
                                options={this.availableYears} />
                        </div>
                        <div class="col-lg-3">
                            <FormGroup>
                                <Label size="sm">Tablica rejestracyjna</Label>
                                <Control.Text
                                    value={this.filterForm.registrationNumber}
                                    onChange={(value: string) => this.filterForm.registrationNumber = value }/>
                            </FormGroup>
                        </div>
                        <div class="col-lg-2">
                            <CheckBoxesFilterForm
                                label='Dni tygodnia'
                                options={this.weekDays}
                                value={this.filterForm.selectedWeekDays}
                                onInput={(value: string[]) => this.filterForm.selectedWeekDays = value }/> 
                        </div>
                    </div>
                </div>
            </div>

            { this.showFilterSection 
                ? <Button size='md' onClick={this.filterSubmit} type={ButtonType.Submit} color={ButtonColor.Primary}>
                    <i class='fa fa-dot-circle-o'/> Generuj raport
                </Button>
            : '' }
        </Card>
       );
    }
});

export default TrafficReportFilterForm;
