import { IMetricLocalization } from '@/logic/types';
import {
  ActionStatusType,
  IComponentDetails,
  CoreLoader,
  DivFragment,
  ModalFooter,
  Button,
  TranslationService,
  InfoMessageService,
  Control,
  ButtonColor
} from '@develos/light-client-vue';
import Vue from 'vue';
import UserMetricRegionsService from './services/UserMetricRegionsService';
import { InternalProps } from './types/InternalProps';

const UserMetricRegions = Vue.component('UserMetricRegions', {
  data: () => {
    return {
      availableLocalizations: [] as IMetricLocalization[],
      assignedUserLocalizations: [] as string[],
      isLoading: false,
      isLocalizationsSectionVisible: false
    };
  },
  props: {
    instanceDetails: Object as () => IComponentDetails,
    passedProps: Object as () => InternalProps
  },
  async created() {
    this.isLoading = true;
    await this.loadInitialData();
    this.isLoading = false;
  },
  methods: {
    async handleUpdateUserLocalizations() {
      this.isLoading = true;
      const result = await UserMetricRegionsService.updateUserLocalizations(this.assignedUserLocalizations, this.passedProps.userUId);
      if (result.status === ActionStatusType.Success) {
        InfoMessageService.success('Operacja została zakończona sukcesem!');
        this.$emit('close');
      } else {
        result.messages.forEach((message: string) => {
          InfoMessageService.error(message);
        });
      }
      this.isLoading = false;
    },
    async loadInitialData() {
      const availableLocalizations = await UserMetricRegionsService.getAllAvailableMetricsLocalizatios();
      const assignedUserLocalizations = await UserMetricRegionsService.getUserActiveRegions(this.passedProps.userUId);
      this.assignedUserLocalizations = assignedUserLocalizations;
      this.availableLocalizations = availableLocalizations;
    }
  },
  render(h: any) {
    return (
      <DivFragment>
        {this.isLoading && (
          <CoreLoader isCentered={true} hasOpacity={true} isSmall={true} />
        )}
        {this.availableLocalizations.map((metricRegion) => (
          <Control.Checkbox
            id={metricRegion.name}
            label={metricRegion.name}
            name={metricRegion.name}
            onChange={() => { 
              this.assignedUserLocalizations.indexOf(metricRegion.uId) > -1
              ? this.assignedUserLocalizations.splice(this.assignedUserLocalizations.indexOf(metricRegion.uId), 1) 
              : this.assignedUserLocalizations.push(metricRegion.uId);
            } }
            checked={this.assignedUserLocalizations.includes(metricRegion.uId)}
          />
        ))}
        <ModalFooter>
          <div class="row">
              <div class="col col-auto ml-auto">
                <Button onClick={this.handleUpdateUserLocalizations} color={ButtonColor.Primary} size="md">
                  {TranslationService.translate('Save')}
                </Button>
              </div>
          </div>
        </ModalFooter>
      </DivFragment>
    );
  }
});

export default UserMetricRegions;
