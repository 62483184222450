import { IMetricLocalization } from '@/logic/types';
import { InfoMessageService, Dropdown, DropdownListItem, DropdownMenu, Badge } from '@develos/light-client-vue';
import Vue from 'vue';

const LocalizationHeaderDropdown = Vue.component('VehicleList', {
  methods: {
    changeCurrentMetricLocalization(localization: IMetricLocalization) {
      this.$store.commit('changeCurrentMetricLocalization', localization);
    }
  },
  computed: {
    localizations(): IMetricLocalization[] {
      return this.$store.state.metricLocalizations as IMetricLocalization[];
    },
    currentLocalization(): IMetricLocalization | null {
      return this.$store.state.currentMetricLocalization;
    }
  },
  render(h: any) {
    return (
      <Dropdown
        id="localizations"
        alighRight={true}
        wrapperClass="dropdown-menu"
        hideChevron={true}
        hasIndicator={true}
        header={
          <div><i class='fas fa-map-marker-alt mr-1'/>{this.currentLocalization?.name}</div>
        }
      >
        <DropdownMenu alighRight={true} labelledBy="languageFlags">
          {this.localizations.map((point: IMetricLocalization) => (
            <DropdownListItem
              onClick={() => {
                this.changeCurrentMetricLocalization(point);
              }}
            >
              {point.name}
              {this.currentLocalization && this.currentLocalization.uId === point.uId 
              ? <Badge size="sm" className="ml-1" variant='1'>Aktywny</Badge> 
              : ''}
            </DropdownListItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
});

export default LocalizationHeaderDropdown;
