import Vue from 'vue';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { IPlayerSource } from '../types/IPlayerSource';
// eslint-disable-next-line
import Player from 'video.js/dist/types/player';

const VideoPlayer = Vue.component('VideoPlayer', {
    props: {
        options: {} as any,
        width: [String, Number],
        height: [String, Number],
        source: Object as () => IPlayerSource,
        uid: String
    },
    data: () => {
        return {
            player: {} as Player
        };
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer as Element, this.options, () => this.$emit('playerReady', this.player));
        this.player.on('error', (e: any) => this.$emit('error', e));

        //@ts-expect-error error
        window.xhook.enable();
        //@ts-expect-error error
        window.xhook.before((request, callback) => {
            if (request.url.includes('live')) {
              const uri = new URL(request.url, undefined);
   
              if (uri.pathname.includes('PortalAPI')) {
                request.url = `https://${uri.host}${uri.pathname}?sourceUID=${this.$props.uid}`;
              } else {
                request.url = `https://${uri.host}/PortalAPI${uri.pathname}?sourceUID=${this.$props.uid}`;
              }
            }
            callback();
          });
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
        //@ts-expect-error error
        window.xhook.disable();
    },
    render(h: any) {
        return (
            <div>
                <video ref="videoPlayer" class="video-js" width={this.width} height={this.height}>
                    <source src={this.$props.source.src} type={this.$props.source.type} />
                </video>
            </div>
        );
    }
});

export default VideoPlayer;
