import { IVehicleRecordDetails } from '@/logic/types';
import { ComponentDataTable, RestApiClient } from '@develos/light-client-vue';
import { IHistoryFilter } from '../types/IHistoryFilter';
import { IVehicleByIdRequest } from '../types/IVehicleByIdRequest';
import { IVehicleListElement } from '../types/IVehicleListElement';

const VehicleListApiClient = {
  getVehicleRecords(request: IHistoryFilter): Promise<ComponentDataTable<IVehicleListElement>> {
    return RestApiClient.post<ComponentDataTable<IVehicleListElement>>('VehicleList/GetVehicles', request);
  },
  getVehicleRecordById(request: IVehicleByIdRequest): Promise<IVehicleRecordDetails> {
    return RestApiClient.post<IVehicleRecordDetails>('VehicleList/GetVehicleById', request);
  }
};

export default VehicleListApiClient;
