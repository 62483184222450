import store from '@/store/store';
import VehicleCategoryAPIClient from '../api/VehicleCategoryAPIClient';
import { IVehicleCategory } from '../types';

class VehicleCategoriesService {
  public async getVehicleCategories(): Promise<IVehicleCategory[]> {
    if (store.state.vehicleCategories.length === 0) {
      const vehicleRecords = await VehicleCategoryAPIClient.getVehicleCategories();
      store.state.vehicleCategories = vehicleRecords;
    }
    return new Promise<IVehicleCategory[]>((resolve, reject) => {
      resolve(store.state.vehicleCategories);
    });
  }
}

export default new VehicleCategoriesService();
