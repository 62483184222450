import Vue from 'vue';
import LiveCameraPreviewService from './services/LiveCameraPreviewService';
import { ICameraStream } from './types/ICameraStream';
import { Card, PageTitle, Dropdown, DropdownMenu, DropdownListItem, InfoMessageService, Badge, DivFragment, Button, ButtonColor, Icon } from '@develos/light-client-vue';
import VideoPlayer from './components/VideoPlayer';
import { IMetricLocalization } from '@/logic/types/IMetricLocalization';
import { IActiveCameraStream } from './types/IActiveCameraStream';
import { playerOptions } from './playerOptions';
import './_liveCameraPreview.scss';

const CameraTimeout = 1000 * 60 * 10;

const LiveCameraPreview = Vue.component('LiveCameraPreview', {
  data: () => {
    return {
      componentName: 'LiveCameraPreview',
      isLoading: false,
      cameraStreams: [] as ICameraStream[],
      selectedCamera: undefined as ICameraStream | undefined,
      activeCamera: undefined as IActiveCameraStream | undefined,
      playerTimeoutId: undefined as number | undefined
    };
  },
  watch: {
    async currentLocalization(value: IMetricLocalization, oldValue: IMetricLocalization) {
      if (this.activeCamera?.uId) {
        await this.stopCameraStream();
      }
      this.cameraStreams = [] as ICameraStream[];
      this.activeCamera = undefined;
      this.selectedCamera = undefined;
      this.isLoading = false;

      await this.getAvailableCameraStreams();
    }
  },
  async created() {
    await this.getAvailableCameraStreams();
  },
  computed: {
    currentLocalization(): IMetricLocalization {
      return this.$store.state.currentMetricLocalization;
    },
    isButtonDisabled() {
      return this.isLoading || (this.selectedCamera?.uId === this.activeCamera?.uId);
    }
  },
  async beforeDestroy() {
    await this.stopCameraStream();
  },
  methods: {
    async getAvailableCameraStreams() {
      this.cameraStreams = await LiveCameraPreviewService.getAvailableCameraStreams(this.currentLocalization.uId);
      this.selectedCamera = this.cameraStreams[0];
    },
    async startCameraStream() {
      this.isLoading = true;

      if (!this.selectedCamera) {
        return;
      }

      await this.stopCameraStream();
      await LiveCameraPreviewService.startProcessingStream(this.selectedCamera.uId);

      try {
        await LiveCameraPreviewService.waitUntilStreamIsReady(this.selectedCamera.uId);
        const source = LiveCameraPreviewService.getStreamSource(this.selectedCamera.uId);
        InfoMessageService.success(`Nawiązano połączenie z kamerą: ${this.selectedCamera.label}`);
        this.activeCamera = { ...this.selectedCamera, source };
        this.setupPlayerTimeout();
      } catch {
        InfoMessageService.error('Wystąpił problem z nawiazaniem połaczenia');
      }
      
      this.isLoading = false;
    },
    async stopCameraStream() {
      if (!this.activeCamera) {
        return;
      }

      InfoMessageService.warning(`Połączenie z kamerą: ${this.activeCamera.label} zostało zatrzymane`);
      await LiveCameraPreviewService.stopProcessingStream(this.activeCamera.uId);
      this.activeCamera = undefined;
    },
    setupPlayerTimeout() {
      clearTimeout(this.playerTimeoutId);
      this.playerTimeoutId = setTimeout(() => this.stopCameraStream(), CameraTimeout);
    }
  },
  render(h: any) {
    return (
      <DivFragment>
        <div class='row'>
          <div class='col-12'>
            <PageTitle title="Podgląd kamer na żywo" />
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <Card title='Widok z kamery' class='player-container'>
              {this.activeCamera && <VideoPlayer uid={this.activeCamera.uId} source={this.activeCamera?.source} options={playerOptions} width={1280} height={720} />}
              {!this.activeCamera && (

                <div class="player-placeholder">
                  <Icon name={'fas fa-video'} />
                  Nawiąż połączenie z wybraną kamerą, aby zobaczyć obraz
                </div>
              )}
            </Card>
          </div>
          <div class='col-4'>
            <Card title="Dostępne kamery">
              {this.cameraStreams.length > 0
                ? (
                  <DivFragment>
                    <Dropdown
                      id="camera-streams"
                      alighRight={false}
                      wrapperClass="dropdown-menu"
                      hasIndicator={true}
                      header={
                        <div><b>{this.selectedCamera?.label}</b></div>
                      }
                    >
                      <DropdownMenu>
                        {this.cameraStreams.map((stream: ICameraStream) => (
                          <DropdownListItem
                            onClick={() => {
                              this.selectedCamera = stream;
                            }}
                          >
                            {stream.label}
                            {(this.activeCamera && this.activeCamera.uId === stream.uId)
                              && <Badge size="sm" className="ml-1" variant='1'>Aktywny</Badge>}
                          </DropdownListItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>

                    <div class='buttons mt-2'>
                      <Button
                        color={ButtonColor.Primary}
                        size="md"
                        onClick={this.startCameraStream}
                        disabled={this.isButtonDisabled}>
                        {this.isLoading ? 'Trwa nawiązywanie połączenia...' : 'Nawiąż połaczenie z kamerą'}
                      </Button>

                      <Button
                        className='ml-2'
                        color={ButtonColor.Secondary}
                        size="md"
                        onClick={this.stopCameraStream}
                        disabled={!this.activeCamera}>
                        Przerwij połaczenie z kamerą
                      </Button>
                    </div>
                  </DivFragment>
                )
                : <b>Brak dostępnych kamer w wybranej lokalizacji</b>}
            </Card>
          </div>
        </div>
      </DivFragment>
    );
  }
});

export default LiveCameraPreview;
