import { IMetricLocalization } from '@/logic/types';
import { RestApiClient } from '@develos/light-client-vue';

const LocalizationHeaderDropdownAPIClient = {
  getMetricsLocalizations(): Promise<IMetricLocalization[]> {
    return RestApiClient.get<IMetricLocalization[]>('Metrics/GetMetricLocalizations');
  }
};

export default LocalizationHeaderDropdownAPIClient;
