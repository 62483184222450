import Vue from 'vue';
import { IVehicleCategory } from '../types';
import { FormGroup, Label, Control, TranslationService } from '@develos/light-client-vue';

const VehicleCategoriesForm = Vue.component('VehicleCategoriesForm', {
    props: {
        options: Array as () => IVehicleCategory[],
        value: Array as () => IVehicleCategory[]
    },
    methods: {
        updateValue(data: IVehicleCategory[]) {
            this.$emit('input', data);
        }
    },
    render(h: any) {
        return (
            <FormGroup>
                <Label class="mr-2">
                    {TranslationService.translateComponent('Kategorie', 'VehicleCategoriesForm')}:
                </Label>
                <Control.MultiSelect
                    options={this.options}
                    value={this.value}
                    placeholder={TranslationService.translateComponent('Wybierz kategorie', 'VehicleCategoriesForm')}
                    onInput={this.updateValue}
                />
            </FormGroup>
        );
    }
});

export default VehicleCategoriesForm;
