import { IReportFilter } from '@/logic/components/Reports/types/IReportFilter';
import { RestApiClient } from '@develos/light-client-vue';

const ReportAPIClient = {
    async createReport(request: IReportFilter): Promise<any> {
        await RestApiClient.downloadFile('Reports/CreateReport', request);
    }
};

export default ReportAPIClient;
