import VehicleDetails from '@/logic/components/VehicleDetails/VehicleDetails';
import TrafficMixin from '@/logic/helpers/TrafficMixin';
import { IMetricLocalization, IVehicleRecordDetails } from '@/logic/types';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Card, Badge, Button, PageTitle, ComponentDataTable, Table, Control, ConfigurationService, FormGroup, Label, emptyComponentDataTable, InfoMessageService, TranslationService, ButtonType, ButtonColor, ButtonVariant } from '@develos/light-client-vue';
import { MetricLocalizationsFilterForm } from '../../filters';
import LiveVehiclesRecordsApiClient from './api/LiveVehiclesRecordsApiClient';
import { ComponentConsts } from './helpers/ComponentConsts';
import { emptyStatusableButton, IStatusableButton } from './types/IStatusableButton';

const LiveVehiclesRecords = TrafficMixin.component('LiveVehiclesRecords', {
  data: () => {
    return {
      liveDataTable: { ...emptyComponentDataTable } as ComponentDataTable<IVehicleRecordDetails>,
      connectionStatus: { ...emptyStatusableButton } as IStatusableButton,
      activeVehicleRecordDetails: null as IVehicleRecordDetails | null,
      hubConnection: undefined as HubConnection | undefined,
      autoUpdateActiveVehicleRecordDetails: true,
      selectedMetricPointPlatformsUIds: [] as string[],
      componentName: 'LiveVehiclesRecords'
    };
  },
  mixins: [TrafficMixin],
  computed: {
    currentLocalization(): IMetricLocalization {
      return this.$store.state.currentMetricLocalization;
    }
  },
  async beforeDestroy(): Promise<void> {
    await this.closeConnection();
  },
  async created() {
    this.connectionStatus = emptyStatusableButton;
    this.liveDataTable = await LiveVehiclesRecordsApiClient.getLiveDataTableConfiguration();

    const centralApiUrl = await this.getCentralApiUrl();
    this.hubConnection = new HubConnectionBuilder().withUrl(`${centralApiUrl}liveVehicleRecordsHub`).build();
    this.hubConnection.on('ReceiveNewVehicleData', this.onRecieveNewVehicle);
    this.hubConnection.on('CloseConnection', async () => { await this.closeConnection(); });
    this.hubConnection.onclose(async () => { await this.closeConnection(); });
  },
  methods: {
    setActiveRecord(vehicleRecordId: number): void {
      const record = this.liveDataTable.values.find((rowRecord: IVehicleRecordDetails) => rowRecord.id === vehicleRecordId);
      if (record !== undefined) {
          this.activeVehicleRecordDetails = record;
      }
    },
    async toogleConnection(): Promise<void> {
      if (this.connectionStatus.connected) {
          await this.closeConnection();
      } else {
          await this.openConnection();
      }
    },
    async onRecieveNewVehicle(records: IVehicleRecordDetails[]) {
      records.reverse().forEach((item) => {
        const existingelement = this.liveDataTable.values.find((x) => x.id === item.id);
        if (existingelement === undefined) {
          this.liveDataTable.values.unshift(item);
        }
      });
      if (this.autoUpdateActiveVehicleRecordDetails) {
        this.activeVehicleRecordDetails = this.liveDataTable.values[0];
      }
    },
    async closeConnection(): Promise<void> {
      if (this.connectionStatus.connected) {
          this.connectionStatus = {
              ...this.connectionStatus,
              buttonText: 'Rozłączanie',
              loading: true
          };
          await this.hubConnection!.stop();
          this.connectionStatus = emptyStatusableButton;
          InfoMessageService.warning('Połączenie na żywo zostało zakończone.');
      }
    },
    async getCentralApiUrl(): Promise<string> {
      let centralApiUrl = await ConfigurationService.getAppSetting(ComponentConsts.AppSettingsCentralApiAddress);
      if (!centralApiUrl.endsWith('/')) {
          centralApiUrl = `${centralApiUrl}/`;
      }
      return centralApiUrl;
    },
    async openConnection(): Promise<void> {
      try {
          this.connectionStatus = {
              ...this.connectionStatus,
              buttonText: 'Łączenie...',
              buttonColor: '4',
              loading: true
          };
          if (this.hubConnection !== undefined) {
              await this.hubConnection!.start();
              await this.hubConnection!.invoke('RegisterPortalClient', this.currentLocalization.uId,
                this.selectedMetricPointPlatformsUIds);
              this.connectionStatus = {
                  buttonText: 'Zakończ połączenie',
                  buttonColor: '2',
                  loading: false,
                  connected: true
              };
              InfoMessageService.warning('Połączenie zostało nawiązane');
          }
      } catch (error) {
          InfoMessageService.error('Wystąpił błąd połączenia');
          this.connectionStatus = emptyStatusableButton;
      }
    }
  },
  watch: {
    async currentLocalization(value: IMetricLocalization, oldValue: IMetricLocalization) {
      if (this.connectionStatus.connected) {
        await this.closeConnection();
        InfoMessageService.warning('Z uwagi na zmianę lokalizacji, połączenie na żywo zostało zamkniętę.');
      }
      this.liveDataTable.values = [];
      this.selectedMetricPointPlatformsUIds = [];
      this.activeVehicleRecordDetails = null;
    },
    async selectedMetricPointPlatformsUIds(value: IMetricLocalization, oldValue: IMetricLocalization) {
      if (this.connectionStatus.connected) {
        await this.closeConnection();
        InfoMessageService.warning('Z uwagi na zmianę punktów preselekcyjnych, połączenie na żywo zostało zamkniętę.');
      }
    }
  },
  render(h: any) {
      const slotedScopes: any = {
        metricPointPlatformUId: (props: { row: IVehicleRecordDetails; columns: string; index: number }) => {
          return this.mapToPlatform(props.row.metricPointPlatformUId);
        },
        isOverweight: (props: {row: IVehicleRecordDetails; columns: string; index: number }) => {
          return (
            <Badge size='sm' variant={this.badgeVariant(props.row.isOverweight)} >
              {this.isOwerweightText(props.row.isOverweight)}
            </Badge>
          );
        },
        weight: (props: { row: IVehicleRecordDetails; columns: string; index: number }) => `${props.row.weight} kg`,
        actions: (props: {row: IVehicleRecordDetails; columns: string; index: number }) => {
          if (this.activeVehicleRecordDetails !== null && props.row.id === this.activeVehicleRecordDetails.id) {
            return <Button size='sm'
                    color='4'>
              <i class='fa fa-info-circle mr-1'/> Aktywny
            </Button>;
          } else {
            return <Button size='sm'
                   color={ButtonColor.Primary}
                    onClick={() => this.setActiveRecord(props.row.id)}>
              <i class='fa fa-pencil-square-o'/> Szczegóły
            </Button>;
          }
        }
      };
      return (
          <div class='row'>
            <div class="col-12">
              <PageTitle title={TranslationService.translateComponent('Ruch drogowy | Podgląd na żywo ', this.componentName)} />
            </div>
            <div class="col-12">
                <div class="row">
                    <div class='col-12 col-lg-6 col-sm-12'>
                        <Card title={TranslationService.translateComponent('Szczegóły przejazdu', this.componentName)}>
                            { this.activeVehicleRecordDetails !== null && <VehicleDetails activeVehicleRecordDetails={this.activeVehicleRecordDetails}/> }
                        </Card>
                    </div>
                    <div class='col-12 col-lg-6 col-sm-12'>
                        <Card title={TranslationService.translateComponent('Podgląd przejazdów', this.componentName)}>
                            <div class='row mb-3'>
                                <div class='col-md-6'>
                                    <MetricLocalizationsFilterForm
                                        value={this.selectedMetricPointPlatformsUIds}
                                        onInput={(value: string[]) => this.selectedMetricPointPlatformsUIds = value}/>
                                </div>
                                <div class='col-md-6'>
                                    <FormGroup>
                                        <Label class="mr-2">
                                            {TranslationService.translateComponent('Automatyczne szczegóły nowego przejazdu', this.componentName)}:
                                        </Label>
                                        <Control.Checkbox
                                            id='AutomaticUpdate'
                                            checked={this.autoUpdateActiveVehicleRecordDetails}
                                            onChange={() => this.autoUpdateActiveVehicleRecordDetails = !this.autoUpdateActiveVehicleRecordDetails}
                                        />
                                    </FormGroup>
                                </div>
                                <div class='col-md-12 mb-2'>
                                    <Button
                                        className="ml-auto"
                                        color={this.connectionStatus.buttonColor}
                                        size="md"
                                        loading={this.connectionStatus.loading}
                                        onClick={this.toogleConnection}>{this.connectionStatus.buttonText}</Button>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-12'>
                                    <Table
                                        data={this.liveDataTable.values}
                                        headers={this.liveDataTable.headers}
                                        options={{
                                            ...this.liveDataTable.options,
                                            skin: 'table-striped'
                                        }}
                                        slots={slotedScopes} />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
          </div>
      );
  }
});

export default LiveVehiclesRecords;
