import Vue from 'vue';
import { emptyFromToFilter, IFromToFilter } from '../types/IFromToFilter';
import { FormGroup, Label, Control } from '@develos/light-client-vue';

const DateTimesFromToForm = Vue.component('DateTimesFromToForm', {
    props: {
        label: String,
        value: Object as () => IFromToFilter<Date>
    },
    data: () => {
        return {
            internalValues: { ...emptyFromToFilter } as IFromToFilter<Date>
        };
    },
    mounted() {
        if (this.value !== undefined) {
            this.internalValues = this.value;
        }
    },
    methods: {
        updateFromElement(value: string) {
            this.internalValues.from = new Date(parseInt(value));
            this.updateProperties();
        },
        updateToElement(value: string) {
            this.internalValues.to = new Date(parseInt(value));
            this.updateProperties();
        },
        updateProperties() {
            this.$emit('input', {
                from: this.internalValues.from || null,
                to: this.internalValues.to || null
            });
        }
    },
    render(h: any) {
        return (
            <FormGroup>
                <Label class="mr-2">
                    {this.label}:
                </Label>
                <div class="row">
                    <FormGroup className="col-lg-6">
                        <div class="row">
                            <Label class="col-3" size="sm">
                                Od
                            </Label>
                            <div class="col-9">
                                <Control.DateTime
                                    value={this.value.from ? this.value.from.getTime().toString() : undefined}
                                    onChange={this.updateFromElement}
                                />
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup className="col-lg-6">
                        <div class="row">
                            <Label class="col-3" size="sm">
                                Do
                            </Label>
                            <div class="col-9">
                                <Control.DateTime
                                    value={this.value.to ? this.value.to.getTime().toString() : undefined}
                                    onChange={this.updateToElement}
                                />
                            </div>
                        </div>
                    </FormGroup>
                </div>
            </FormGroup>
        );
    }
});

export default DateTimesFromToForm;
