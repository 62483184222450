import Vue from 'vue';
import { EnvitRouter } from '@develos/light-client-vue';

Vue.use(EnvitRouter);

const router = new EnvitRouter({
    routes: []
});

export default router;
