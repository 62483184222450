import { ButtonColor } from '@develos/light-client-vue';

export type IStatusableButton = {
    buttonText: string;
    buttonColor: string;
    loading: boolean;
    connected: boolean;
}

export const emptyStatusableButton: IStatusableButton = {
    buttonText: 'Rozpocznij podgląd na żywo',
    buttonColor: ButtonColor.Primary,
    loading: false,
    connected: false
};
