import VehicleCategoriesService from '@/logic/services/VehicleCategoriesService';
import { IVehicleCategory } from '@/logic/types';

const VehicleDetailsService = {
  async getVehicleCategories(): Promise<IVehicleCategory[]> {
      const vehicleCategories = await VehicleCategoriesService.getVehicleCategories();
      return vehicleCategories;
  }
};

export default VehicleDetailsService;
