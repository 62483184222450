import TrafficMixin from '@/logic/helpers/TrafficMixin';
import { CoreLoader, Badge, Table, emptyComponentDataTable, getDefaultTableTexts } from '@develos/light-client-vue';
import { IVehicleCategory, IVehicleRecordDetails } from '@/logic/types';
import { IAxleData } from '@/logic/types/IAxleData';
import VehicleDetailsService from './services/VehicleDetailsService';
import AxesDistance from '../AxlesDistance/AxlesDistance';
import ListGroupItem from './services/ListGroup/ListGroupItem';
import ListGroup from './services/ListGroup/ListGroup';
import { IBinaryRecordDetails, VehicleImageType } from '@/logic/types/IBinaryRecordDetails';

const VehicleDetails = TrafficMixin.component('VehicleDetails', {
  data: () => {
    return {
      isLoading: false,
      availableCategories: [] as IVehicleCategory[],
      componentName: 'VehicleDetails'
    };
  },
  mixins: [TrafficMixin],
  methods: {
    isSensorMissed(value: boolean): string {
      if (value) {
          return 'Tak';
      }
      return 'Nie';
    },
    isHighExceededText(record: IVehicleRecordDetails): string {
      if (record.isHighExceeded) {
          return 'Przekroczona wysokość';
      }
      return 'Wysokość poprawna';
    },
    getCategoryDesc(categoryId: number): string {
      const category = this.availableCategories.find((obj: IVehicleCategory) => {
        return obj.id === categoryId;
      });
      if (category !== undefined) {
          return category.name;
      }
      return categoryId.toString();
    },
    sortImages(records: IBinaryRecordDetails[]): IBinaryRecordDetails[] {
      const fullVehicle = [...records].filter(x => x.imageType === VehicleImageType.FullVehicle);
      const frontVehicles = [...records].filter(x => x.imageType === VehicleImageType.FrontOfVehicle);
      const plates = [...records].filter(x => x.imageType === VehicleImageType.RegistrationPlate);

      const data: IBinaryRecordDetails[] = [...fullVehicle, ...frontVehicles, ...plates];
      return data;
    } 
  },
  computed: {
    axelsDistances(): number[] {
      if (this.activeVehicleRecordDetails === null) {
        return [];
      }
      const ard = this.activeVehicleRecordDetails;
      const distances: number[] = [];
      ard.axleWeightsList.forEach((element: IAxleData) => {
        if (element.nextAxleDist !== 0) {
          distances.push(element.nextAxleDist);
        }
      });
      return distances;
    },
    axelsWeights(): number[] {
      if (this.activeVehicleRecordDetails == null) {
        return [];
      }
      return this.activeVehicleRecordDetails.axleWeightsList.filter((x) => x.axleWeight > 0).map((x) => x.axleWeight);
    }
  },
  async created() {
    this.isLoading = true;
    this.availableCategories = await VehicleDetailsService.getVehicleCategories();
    this.isLoading = false;
  },
  props: {
    activeVehicleRecordDetails: Object as () => IVehicleRecordDetails,
    isModalDataLoading: Boolean
  },
  render(h: any) {
    return (
      <div class='row'>
        { this.isModalDataLoading && <CoreLoader isCentered={true} hasOpacity={true} isSmall={true} /> }
        {this.activeVehicleRecordDetails && <div class='col-sm-12'>
            <div class='row'>
              <div class='col-sm-12'>
                <div class='row mb-3'>
                  {this.sortImages(this.activeVehicleRecordDetails.binaryRecords).map((elem) => {
                    return <div class='col-sm-6 mb-2'>
                            <img style='max-width:100%;' 
                              src={elem.image}/>
                            </div>;
                  })}                    
                </div>
                <div class='row mb-3'>
                  <div class='col-sm-6'>
                    <ListGroup>
                      <ListGroupItem>Numer rejestracyjny: <b>{this.activeVehicleRecordDetails.registrationNumber}</b></ListGroupItem>
                      <ListGroupItem>Czas przejazdu: <b>{this.activeVehicleRecordDetails.dateCreated}</b></ListGroupItem>
                      <ListGroupItem>Lokalizacja: <b>{this.mapToFullPlatform(this.activeVehicleRecordDetails.metricPointPlatformUId)}</b></ListGroupItem>
                      <ListGroupItem>
                        Waga: <b>{this.activeVehicleRecordDetails.weight} kg 
                        (<i>min: {this.activeVehicleRecordDetails.minAvailableWeight} kg, 
                        max: {this.activeVehicleRecordDetails.maxAvailableWeight} kg</i>)</b>
                      </ListGroupItem>
                      {(this.activeVehicleRecordDetails.weight - this.activeVehicleRecordDetails.weightLimit) > 0
                        ? <ListGroupItem>
                          Wartość przekorczenia masy: 
                          <b>{this.activeVehicleRecordDetails.weight - this.activeVehicleRecordDetails.weightLimit} kg</b>
                        </ListGroupItem>
                        : ''}
                      <ListGroupItem>
                        Prędkość: <b>{this.activeVehicleRecordDetails.speed} km/h</b>
                      </ListGroupItem>
                      <ListGroupItem>Nr pasa: <b>{this.activeVehicleRecordDetails.lane}</b></ListGroupItem>
                    </ListGroup>
                  </div>
                  <div class='col-sm-6'>
                    <ListGroup>
                      <ListGroupItem>
                        Limit wagowy: <b>{this.activeVehicleRecordDetails.weightLimit} kg</b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Liczba osi: <b>{this.activeVehicleRecordDetails.axleCount}</b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Kategoria: <b>{this.getCategoryDesc(this.activeVehicleRecordDetails.categoryCost)}</b>
                      </ListGroupItem>
                      <ListGroupItem>
                        Przeciążenie: 
                        <Badge className='ml-1' size='sm' variant={this.activeVehicleRecordDetails.isOverweight ? '4' : '3'}>
                          {this.isOwerweightText(this.activeVehicleRecordDetails.isOverweight)}
                        </Badge>
                      </ListGroupItem>
                      <ListGroupItem>
                        Wysokość: 
                        <Badge className='ml-1' size='sm' variant={this.activeVehicleRecordDetails.isHighExceeded ? '4' : '3'}>
                          {this.isHighExceededText(this.activeVehicleRecordDetails)}
                        </Badge>
                      </ListGroupItem>
                      <ListGroupItem>
                        Ominięty czujnik: 
                        <Badge className='ml-1' size='sm' variant={this.activeVehicleRecordDetails.lane === 0 ? '4' : '3'}>
                          {this.isSensorMissed(this.activeVehicleRecordDetails.lane === 0)}
                        </Badge>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                </div>
              </div>
            </div>
            <div class='row mb-3'>
              <div class='col-sm-12'>
                <h4><strong>Osie</strong></h4>
                <Table
                  data={this.activeVehicleRecordDetails.axleWeightsList.filter(x => x.axleWeight !== 0)}
                  headers={['axleNumber', 'axleWeight', 'axleOverWeight', 'minAvailableWeight', 'maxAvailableWeight']}
                  options={{ ...emptyComponentDataTable,
                    skin: 'table-striped',
                    texts: getDefaultTableTexts(), 
                    filterable: null,
                      pagination: {
                          show: false
                      },
                    headings: {
                      axleNumber: 'Numer', 
                      axleWeight: 'Nacisk', 
                      axleOverWeight: 'Przeciążenie', 
                      minAvailableWeight: 'Wart. min.', 
                      maxAvailableWeight: 'Wart. maks.'
                    }
                  }}
                />
              </div>
              { this.activeVehicleRecordDetails.axleGroups.length > 0 
                ? <div class='col-sm-12 mt-2'>
                    <h4><strong>Grupy osi</strong></h4>
                    <Table
                      data={this.activeVehicleRecordDetails.axleGroups}
                      headers={['weight', 'overWeight', 'minAvailableWeight', 'maxAvailableWeight']}
                      options={{ ...emptyComponentDataTable,
                        skin: 'table-striped',
                        texts: getDefaultTableTexts(), 
                        filterable: null,
                          pagination: {
                           show: false
                          },
                          headings: {
                          weight: 'Nacisk', 
                          overWeight: 'Przeciążenie', 
                          minAvailableWeight: 'Wart. min.', 
                          maxAvailableWeight: 'Wart. maks.'
                        }
                      }}
                    />
                  </div>
              : '' }
            </div>
            <div class='col-sm-12'>
              <div class='row'>
                <h4><strong>Odległości</strong></h4>
              </div>
              <AxesDistance axelsDistances={this.axelsDistances} axelsWeights={this.axelsWeights}/>
            </div>          
          </div> }
        </div>
    );
  }
});

export default VehicleDetails;
