import Vue from 'vue';
import { IMetricLocalization, IMetricPointPlatform } from '../types';
import { FormGroup, Label, TranslationService, Control } from '@develos/light-client-vue';

const MetricLocalizationsFilterForm = Vue.component('MetricLocalizationsFilterForm', {
    props: {
        value: Array as () => string[]
    },
    data: () => {
        return {
            selectedPlatforms: [] as string[],
            componentName: 'MetricLocalizationsFilterForm'
        };
    },
    created() {
        if (this.value !== undefined) {
            this.selectedPlatforms = this.value;
        }
    },
    computed: {
        currentLocalization(): IMetricLocalization {
            return this.$store.state.currentMetricLocalization;
        }
    },
    watch: {
        currentLocalization() {
            this.selectedPlatforms = [];
            this.$emit('input', []);
        }
    },
    methods: {
        updateSelected(value: string) {
            if (this.selectedPlatforms.includes(value)) {
                const index = this.selectedPlatforms.indexOf(value);
                this.selectedPlatforms.splice(index, 1);
            } else {
                this.selectedPlatforms.push(value);
            }
            this.$emit('input', this.selectedPlatforms);
        }
    },
    render(h: any) {
        return (
            <FormGroup>
                <Label class="mr-2">
                    {TranslationService.translateComponent('Dostępne punkty preselekcyjne', this.componentName)}:
                </Label>
                { this.currentLocalization !== null 
                    && this.currentLocalization.metricPointPlatforms.map((item: IMetricPointPlatform) => {
                        return (
                            <Control.Checkbox
                                id={`pointPlatform${item.uId}`}
                                checked={this.selectedPlatforms.includes(item.uId)}
                                label={item.name}
                                onChange={() => this.updateSelected(item.uId)}
                            />
                        );
                    })}
            </FormGroup>
        );
    }
});

export default MetricLocalizationsFilterForm;
