import { IVehicleCategory } from '@/logic/types/IVehicleCategory';
import { RestApiClient } from '@develos/light-client-vue';

const VehicleCategoryAPIClient = {
  async getVehicleCategories(): Promise<IVehicleCategory[]> {
    const result = await RestApiClient.get<IVehicleCategory[]>('Category/GetCost323Categories');
    return result;
  }
};

export default VehicleCategoryAPIClient;
