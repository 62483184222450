import { PortalConfigService, CoreLoader, InfoMessageService, UserService } from '@develos/light-client-vue';
import '@develos/light-client-vue/build/vue-core.css';
import Vue, { CreateElement, VNode } from 'vue';
import Vuelidate from 'vuelidate';
import App from './App';
import Components from './logic/components';
import LocalizationHeaderDropdownService from './logic/components/LocalizationHeaderDropdown/services/LocalizationHeaderDropdownService';
import Layouts from './logic/layouts';
import Skins from './logic/skins';

Vue.use(Vuelidate);

const AppLoader = Vue.component('AppLoader', {
    async created() {
        await PortalConfigService.loadLightClientCore(this.$store, this.$router, Skins, Layouts, Components);
        await this.loadPortalContet();
        this.isLoading = false;
    },
    methods: {
        async loadPortalContet() {
            if (UserService.isLoggedIn()) {
                await this.loadUserLocalizations();
            }
        },
        async loadUserLocalizations() {
            try {
                await LocalizationHeaderDropdownService.loadUserMetricsLocalizations();
            } catch (error) {
                InfoMessageService.error('Wystąpił błąd podczas ładowania lokalizacji. Spróbuj ponownie');
            }
        }
    },
    data: () => {
        return {
            isLoading: true as boolean
        };
    },
    render(h: CreateElement): VNode {
        return (
            this.isLoading ? <CoreLoader isLoading={true} isCentered={true}/> : <App/>
        );
    }
});

export default AppLoader;
