import { GlobalConfigProvider } from '@develos/light-client-vue';
import LiveCameraPreviewAPIClient from '../api/LiveCameraPreviewAPIClient';
import { ICameraStream } from '../types/ICameraStream';
import { IPlayerSource } from '../types/IPlayerSource';
import { StreamStatus } from '../types/IStreamStatus';

const LiveCameraPreviewService = {
    getAvailableCameraStreams(localizationUid: string): Promise<ICameraStream[]> {
        return LiveCameraPreviewAPIClient.getAvailableCameraStreams(localizationUid);
    },
    async startProcessingStream(streamUId: string): Promise<void> {
        await LiveCameraPreviewAPIClient.startProcessingStream(streamUId);
    },
    async stopProcessingStream(streamUId: string): Promise<void> {
        await LiveCameraPreviewAPIClient.stopProcessingStream(streamUId);
    },
    async waitUntilStreamIsReady(streamUId: string): Promise<void> {
        let retryCount = 0;
        return new Promise<void>((resolve, reject) => {
            const timer = setInterval(async () => {
                const streamStatus = await LiveCameraPreviewAPIClient.getStreamStatus(streamUId);
                if (streamStatus.status === StreamStatus.Ready) {
                    clearInterval(timer);
                    resolve();
                }

                if (retryCount > 100) {
                    reject();
                }
                retryCount++;
            }, 500);
        });
    },
    getStreamSource(streamUId: string): IPlayerSource {
        const configValues = GlobalConfigProvider.getValue();

        return {
            src: `${configValues.portalApiUrl}LiveCameraPreview/${streamUId}/index.m3u`,
            type: 'application/x-mpegURL'
        };
    }
};

export default LiveCameraPreviewService;
