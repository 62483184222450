import { IMetricLocalization } from '@/logic/types';
import { IActionStatus, RestApiClient } from '@develos/light-client-vue';
import { IUpdateUserMetricRegionsRequest } from '../types/IUpdateUserMetricRegionsRequest';

const UserMetricRegionsServiceAPIClient = {
  isSectionVisible(): Promise<boolean> {
    return RestApiClient.get<boolean>('UserMetricRegions/IsSectionVisible');
  },
  getAllAvailableMetricsLocalizatios(): Promise<IMetricLocalization[]> {
    return RestApiClient.get<IMetricLocalization[]>('UserMetricRegions/GetAllMetricLocalizations');
  },
  getUserActiveRegions(userUId: string): Promise<string[]> {
    return RestApiClient.get<string[]>(`UserMetricRegions/GetUserActiveRegions/${userUId}`);
  },
  updateUserLocalizations(metricLocalizationsUIds: string[], userUId: string): Promise<IActionStatus> {
    const request: IUpdateUserMetricRegionsRequest = {
      userUId,
      metricLocalizationsUIds
    };
    return RestApiClient.post<IActionStatus>('UserMetricRegions/UpdateUserLocalizations', request);
  }
};

export default UserMetricRegionsServiceAPIClient;
