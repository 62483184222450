import { IVehicleRecordDetails } from '@/logic/types';
import { ComponentDataTable, RestApiClient } from '@develos/light-client-vue';

const LiveVehiclesRecordsApiClient = {
    getLiveDataTableConfiguration(): Promise<ComponentDataTable<IVehicleRecordDetails>> {
      return RestApiClient.get<ComponentDataTable<IVehicleRecordDetails>>('LiveVehiclesRecords/GetLiveDataTableConfiguration');
    }
};

export default LiveVehiclesRecordsApiClient;
