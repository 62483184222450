import Vue from 'vue';
import { emptyFromToFilter, IFromToFilter } from '../types/IFromToFilter';
import { FormGroup, Label, Control, FormGroupVariants } from '@develos/light-client-vue';

const NumericFromToForm = Vue.component('NumericFromToForm', {
    props: {
        label: String,
        value: Object as () => IFromToFilter<number>,
        minValue: String || undefined
    },
    data: () => {
        return {
            internalValues: { ...emptyFromToFilter } as IFromToFilter<number>
        };
    },
    mounted() {
        if (this.value !== undefined) {
            this.internalValues = this.value;
        }
    },
    methods: {
        updateFromElement(value: any) {
            this.internalValues.from = parseInt(value);
            this.updateProperties();
        },
        updateToElement(value: any) {
            this.internalValues.to = parseInt(value);
            this.updateProperties();
        },
        updateProperties() {
            this.$emit('input', {
                from: this.internalValues.from,
                to: this.internalValues.to
            });
        }
    },
    render(h: any) {
        return (
            <FormGroup>
                <Label class="mr-2" size="sm">
                    {this.label}:
                </Label>
                <div class="row">
                    <FormGroup className="col-lg-6" variant={FormGroupVariants.Inline}>
                        <Label class="mr-1" size="sm">
                            Od
                        </Label>
                        <Control.Number
                            minValue={this.minValue}
                            value={this.value.from ? this.value.from.toString() : null}
                            onChange={(value: string) => this.updateFromElement(value)}
                        />
                    </FormGroup>
                    <FormGroup className="col-lg-6" variant={FormGroupVariants.Inline}>
                        <Label class="mr-1" size="sm">
                            Do
                        </Label>
                        <Control.Number
                            minValue={this.minValue}
                            value={this.value.to ? this.value.to.toString() : null}
                            onChange={(value: string) => this.updateToElement(value)}
                        />
                    </FormGroup>
                </div>
            </FormGroup>
        );
    }
});

export default NumericFromToForm;
