import Vue, { CreateElement, VNode } from 'vue';

const App = Vue.component('App', {
    render(h: CreateElement): VNode {
        return (
            <router-view/>
        );
    }
});

export default App;
