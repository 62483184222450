import { RestApiClient } from '@develos/light-client-vue';
import { ICameraStream } from '../types/ICameraStream';
import { IStreamStatus } from '../types/IStreamStatus';

const LiveCameraPreviewAPIClient = {
    getAvailableCameraStreams(localizationUid: string): Promise<ICameraStream[]> {
        return RestApiClient.get<ICameraStream[]>(`LiveCameraPreview/GetAvailableCameraStreams?localizationUid=${localizationUid}`);
    },
    async startProcessingStream(streamUId: string): Promise<void> {
        await RestApiClient.post(`LiveCameraPreview/${streamUId}/Start`);
    },
    async stopProcessingStream(streamUId: string): Promise<void> {
        await RestApiClient.post(`LiveCameraPreview/${streamUId}/Stop`);
    },
    async getStreamStatus(streamUId: string): Promise<IStreamStatus> {
        return RestApiClient.post<IStreamStatus>(`LiveCameraPreview/${streamUId}/status`);
    }
};

export default LiveCameraPreviewAPIClient;
