import Vue from 'vue';
import { IHistoryFilter, emptyHistoryFilter } from '../types/IHistoryFilter';
import { Card, Button, TranslationService, ButtonColor } from '@develos/light-client-vue';
import { MetricLocalizationsFilterForm, VehiclesWarningsForm, VehicleCategoriesForm, NumericFromToForm, DatesFromToForm } from '../../../filters';
import { IFromToFilter, IVehicleCategory } from '@/logic/types';
import { IVehicleWarningFilter } from '@/logic/types/IVehicleWarningFilter';

const VehicleListFilter = Vue.component('VehicleListFilter', {
    props: {
        showExpandedForm: Boolean as () => boolean | undefined,
        availableCategories: Array as () => IVehicleCategory[]
    },
    data: () => {
        return {
            showFilterSection: true,
            componentName: 'VehicleList',
            filterForm: { ...emptyHistoryFilter } as IHistoryFilter
        };
    },
    mounted() {
        if (this.showExpandedForm !== undefined) {
            this.showFilterSection = this.showExpandedForm;
        }
    },
    methods: {
        filterSubmit() {
            this.$emit('filterSubmit', this.filterForm);
        },
        filterReset() {
            this.$emit('filterReset');
        }
    },
    render(h: any) {
        return (
            <Card title={TranslationService.translateComponent('Filtrowanie wyników', this.componentName)}>
                <div class="row mb-2">
                    <div class="col-lg-3">
                        <MetricLocalizationsFilterForm
                            value={this.filterForm.vehicleRecordsRequest.metricPointsUIds}
                            onInput={(value: string[]) => this.filterForm.vehicleRecordsRequest.metricPointsUIds = value } />
                    </div>
                    <div class="col-lg-3">
                        <VehiclesWarningsForm
                            value={this.filterForm.wehiclesWarnings}
                            onInput={(value: IVehicleWarningFilter) => this.filterForm.wehiclesWarnings = value } />
                    </div>
                    <div class="col-lg-6">
                        <VehicleCategoriesForm
                            options={this.availableCategories}
                            value={this.filterForm.categories}
                            onInput={(value: IVehicleCategory[]) => this.filterForm.categories = value } />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-6">
                        <NumericFromToForm
                            label='Liczba osi'
                            minValue="0"
                            value={this.filterForm.axelsCounts}
                            onInput={(value: IFromToFilter<number>) => this.filterForm.axelsCounts = value } />
                    </div>
                    <div class="col-lg-6">
                        <DatesFromToForm
                            label='Przedział czasowy'
                            value={this.filterForm.dates}                                            
                            onInput={(value: IFromToFilter<Date>) => this.filterForm.dates = value } />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-6">
                        <NumericFromToForm
                            label='Przekroczona DCM'
                            minValue="0"
                            value={this.filterForm.overweightLimit}
                            onInput={(value: IFromToFilter<number>) => this.filterForm.overweightLimit = value } />
                    </div>
                    <div class="col-lg-6">
                        <NumericFromToForm
                            label='Stopień przekroczenia nacisków osi'
                            minValue="0"
                            value={this.filterForm.axleOverweight}
                            onInput={(value: IFromToFilter<number>) => this.filterForm.axleOverweight = value } />
                    </div>
                </div>
                <Button
                    onClick={this.filterSubmit}
                    color={ButtonColor.Primary}
                    size="md"
                >
                    {TranslationService.translate('Filter')}
                </Button>
            </Card>
        );
    }
});

export default VehicleListFilter;
