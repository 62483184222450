import VehicleCategoriesService from '@/logic/services/VehicleCategoriesService';
import { IReportFilter } from '../types/IReportFilter';
import ReportAPIClient from '../api/ReportAPIClient';
import { IVehicleCategory } from '@/logic/types';
import { IActionStatus } from '@develos/light-client-vue';

const ReportsService = {
  async getVehicleCategories(): Promise<IVehicleCategory[]> {
      const vehicleCategories = await VehicleCategoriesService.getVehicleCategories();
      return vehicleCategories;
  },
  async createReport(request: IReportFilter): Promise<IActionStatus> {
    return ReportAPIClient.createReport(request);
  }
};

export default ReportsService;
