import Vue from 'vue';
import { emptyVehicleWarningFilter, IVehicleWarningFilter } from '../types/IVehicleWarningFilter';
import { FormGroup, Label, TranslationService, Control } from '@develos/light-client-vue';

const VehiclesWarningsForm = Vue.component('VehiclesWarningsForm', {
    props: {
        value: Object as () => IVehicleWarningFilter
    },
    data: () => {
        return {
            internalValues: { ...emptyVehicleWarningFilter } as IVehicleWarningFilter,
            componentName: 'VehiclesWarningsForm'
        };
    },
    mounted() {
        if (this.value !== undefined) {
            this.internalValues = this.value;
        }
    },
    methods: {
        updateOverweight() {
            this.internalValues.isOverweight = !this.internalValues.isOverweight;
            this.updateProperties();
        },
        updateOverheight() {
            this.internalValues.isOverheight = !this.internalValues.isOverheight;
            this.updateProperties();
        },
        updateSensorAvoided() {
            this.internalValues.isSensorAvoided = !this.internalValues.isSensorAvoided;
            this.updateProperties();
        },
        updateProperties() {
            this.$emit('input', {
                isOverweight: this.internalValues.isOverweight,
                isOverheight: this.internalValues.isOverheight,
                isSensorAvoided: this.internalValues.isSensorAvoided
            });
        }
    },
    render(h: any) {
        return (
            <FormGroup>
                <Label class="mr-2">
                    {TranslationService.translateComponent('Pojazdy z przekroczeniami', this.componentName)}:
                </Label>
                <Control.Checkbox
                    id="vehiclesWarningOverweight"
                    checked={this.value.isOverweight}
                    label={TranslationService.translateComponent('Przekroczona waga', this.componentName)}
                    onChange={this.updateOverweight}
                />
                <Control.Checkbox
                    id="vehiclesWarningOverheight"
                    checked={this.value.isOverheight}
                    label={TranslationService.translateComponent('Przekroczona wysokość', this.componentName)}
                    onChange={this.updateOverheight}
                />
                <Control.Checkbox
                    id="vehiclesWarningAvoidSensor"
                    checked={this.value.isSensorAvoided}
                    label={TranslationService.translateComponent('Ominięty czujnik nacisku', this.componentName)}
                    onChange={this.updateSensorAvoided}
                />
            </FormGroup>
        );
    }
});

export default VehiclesWarningsForm;
