import { IVehicleRecordsRequest } from '@/logic/types/IVehicleRecordsRequest';
import { IAxleDetails } from './IAxleDetails';
import { required } from 'vuelidate/lib/validators';

export type ICalibrationVehicleDetails = {
    plateNumber: string;
    date: {
        date: string | null;
    };
    weight: number;
    axleNumber: number;
    axleGroupsWeight: number;
    axleDetails: IAxleDetails[];
    vehicleRecordsRequest: IVehicleRecordsRequest;
}

export const emptyCalibrationVehicleDetails: ICalibrationVehicleDetails = {
    plateNumber: '',
    date: {
        date: null
    },
    weight: 0,
    axleNumber: 2,
    axleGroupsWeight: 0,
    axleDetails: [],
    vehicleRecordsRequest: {
        metricLocalizationUid: '',
        metricPointsUIds: []
    }
};

export const calibrationValidationSchema = {
    plateNumber: { required },
    weight: { required },
    axleGroupsWeight: { required },
    axleNumber: { required }
};
