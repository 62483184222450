import Vue from 'vue';
import Vuex from 'vuex';
import { EnvitStore } from '@develos/light-client-vue';
import { AppState, appStateMutations, emptyAppState } from './AppState';

Vue.use(Vuex);

export default new EnvitStore<AppState>({
    state: { ...emptyAppState },
    mutations: { ...appStateMutations }
});
