import AxlesDistance from './AxlesDistance/AxlesDistance';
import CalibrationReports from './CalibrationReports/CalibrationReports';
import LiveCameraPreview from './LiveCameraPreview/LiveCameraPreview';
import LiveVehiclesRecords from './LiveVehiclesRecords/LiveVehiclesRecords';
import LocalizationHeaderDropdown from './LocalizationHeaderDropdown/LocalizationHeaderDropdown';
import Reports from './Reports/Reports';
import UserMetricRegions from './UserMetricRegions/UserMetricRegions';
import VehicleDetails from './VehicleDetails/VehicleDetails';
import VehicleList from './VehicleList/VehicleList';
import PlatformStatuses from './PlatformStatuses/PlatformStatuses';

export default {
    AxlesDistance,
    CalibrationReports,
    LocalizationHeaderDropdown,
    VehicleDetails,
    VehicleList,
    UserMetricRegions,
    LiveVehiclesRecords,
    Reports,
    LiveCameraPreview,
    PlatformStatuses
};
