import { RestApiClient } from '@develos/light-client-vue';
import { ICalibrationVehicleDetails } from '../types/ICalibrationVehicleDetails';

const CalibrationReportsAPIClient = {
  async createCalibrationReport(calibrationVehicleDetails: ICalibrationVehicleDetails): Promise<any> {
    await RestApiClient.downloadFile('Calibration/GetCalibrationReport', calibrationVehicleDetails);
  }
};

export default CalibrationReportsAPIClient;
