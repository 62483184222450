export type IVehicleWarningFilter = {
    isOverweight: boolean;
    isOverheight: boolean;
    isSensorAvoided: boolean;
}

export const emptyVehicleWarningFilter: IVehicleWarningFilter = {
    isOverweight: false,
    isOverheight: false,
    isSensorAvoided: false
};
