import { IActionStatus } from '@develos/light-client-vue';
import UserMetricRegionsServiceAPIClient from '../api/UserMetricRegionsServiceAPIClient';

const UserMetricRegionsService = {
  async isSectionVisible() {
    const isSectionVisible = await UserMetricRegionsServiceAPIClient.isSectionVisible();
    return isSectionVisible;
  },
  async getAllAvailableMetricsLocalizatios() {
    const localizations = await UserMetricRegionsServiceAPIClient.getAllAvailableMetricsLocalizatios();
    return localizations;
  },
  async getUserActiveRegions(userUid: string) {
    const regionsUIds = await UserMetricRegionsServiceAPIClient.getUserActiveRegions(userUid);
    return regionsUIds;
  },
  async updateUserLocalizations(localizationUIds: string[], userUid: string): Promise<IActionStatus> {
    const status = await UserMetricRegionsServiceAPIClient.updateUserLocalizations(localizationUIds, userUid);
    return status;
  }
};

export default UserMetricRegionsService;
