import { IFromToFilter, IVehicleCategory } from '@/logic/types';
import { emptyFromToFilter } from '@/logic/types/IFromToFilter';
import { IVehicleRecordsRequest } from '@/logic/types/IVehicleRecordsRequest';
import { emptyVehicleWarningFilter, IVehicleWarningFilter } from '@/logic/types/IVehicleWarningFilter';

export type IReportFilter = {
    wehiclesWarnings: IVehicleWarningFilter;
    categories: IVehicleCategory[];
    dates: IFromToFilter<Date>;
    weight: IFromToFilter<number>;
    speed: IFromToFilter<number>;
    axelsCounts: IFromToFilter<number>;
    vehicleRecordsRequest: IVehicleRecordsRequest;
    selectedReportType: number | null;
    selectedReportFormat: number;
    selectedAggregationType: number | null;
    selectedMonth: number | null;
    selectedYear: number | null;
    selectedWeekDays: string[];
    registrationNumber: string;
}

export const emptyReportFilter: IReportFilter = {
    wehiclesWarnings: emptyVehicleWarningFilter,
    categories: [],
    dates: { ...emptyFromToFilter },
    weight: { ...emptyFromToFilter },
    speed: { ...emptyFromToFilter },
    axelsCounts: { ...emptyFromToFilter },
    vehicleRecordsRequest: {
      metricLocalizationUid: '',
      metricPointsUIds: []
    },
    selectedReportType: 0,
    selectedReportFormat: 0,
    selectedAggregationType: null,
    selectedMonth: null,
    selectedYear: null,
    selectedWeekDays: [],
    registrationNumber: ''
};
