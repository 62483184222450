import Vue from 'vue';
import { FormGroup, Label, TranslationService, Control, ISelectInputOption } from '@develos/light-client-vue';

const CheckBoxesFilterForm = Vue.component('CheckBoxesFilterForm', {
    props: {
        value: Array as () => string[],
        label: String,
        options: Array as () => ISelectInputOption[]
    },
    data: () => {
        return {
            selectedOptions: [] as string[],
            componentName: 'CheckBoxesFilterForm'
        };
    },
    created() {
        if (this.value !== undefined) {
            this.selectedOptions = this.value;
        }
    },
    updated() {
        if (this.value !== undefined) {
            this.selectedOptions = this.value;
        }
    },
    methods: {
        updateSelected(value: string) {
            if (this.selectedOptions.includes(value)) {
                const index = this.selectedOptions.indexOf(value);
                this.selectedOptions.splice(index, 1);
            } else {
                this.selectedOptions.push(value);
            }
            this.$emit('input', this.selectedOptions);
        }
    },
    render(h: any) {
        return (
            <FormGroup>
                <Label class="mr-2">
                    {this.label}:
                </Label>
                { this.options.map((item: ISelectInputOption) => {
                    return (
                        <Control.Checkbox
                            id={item.key}
                            checked={this.selectedOptions.includes(item.key)}
                            label={item.value}
                            onChange={() => this.updateSelected(item.key)}
                        />
                    );
                })}
            </FormGroup>
        );
    }
});

export default CheckBoxesFilterForm;
