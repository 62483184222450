import { IFromToFilter, IVehicleCategory } from '@/logic/types';
import { emptyFromToFilter } from '@/logic/types/IFromToFilter';
import { IVehicleRecordsRequest } from '@/logic/types/IVehicleRecordsRequest';
import { emptyVehicleWarningFilter, IVehicleWarningFilter } from '@/logic/types/IVehicleWarningFilter';

export type IHistoryFilter = {
  wehiclesWarnings: IVehicleWarningFilter;
  categories: IVehicleCategory[];
  dates: IFromToFilter<Date>;
  overweightLimit: IFromToFilter<number>;
  axelsCounts: IFromToFilter<number>;
  axleOverweight: IFromToFilter<number>;
  vehicleRecordsRequest: IVehicleRecordsRequest;
}

export const emptyHistoryFilter: IHistoryFilter = {
  wehiclesWarnings: { ...emptyVehicleWarningFilter },
  categories: [],
  dates: { ...emptyFromToFilter },
  overweightLimit: { ...emptyFromToFilter },
  axelsCounts: { ...emptyFromToFilter },
  axleOverweight: { ...emptyFromToFilter },
  vehicleRecordsRequest: {
    metricLocalizationUid: '',
    metricPointsUIds: []
  }
};
